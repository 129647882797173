







import LoginComponent from '@/components/admin/LoginComponent.vue';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import AuthController from "@/controllers/AuthController"

@Component({
  components:{
    LoginComponent,
  }
})
export default class Auth extends Vue {

  public get user(){
    return AuthController.user;
  }
  @Watch("user")
  public onUserChange(user:any){
    if(user){
      this.$router.push('/admin')
    }
  }
  public mounted():void {
    
  }

  public beforeDestroy():void {
    
  }

}
