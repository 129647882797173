












































import User from '@/models/User';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import UserController from "@/controllers/UserController"
import Post from '@/models/Post';
import PostHelper from '@/helpers/PostHelper';
import PostEdit from './PostEdit.vue';
import _ from 'lodash';

@Component({
  components:{
    PostEdit
  }
})
export default class UserEdit extends Vue {
  @Prop() user!:User;
  // userClone!:User;
  public attached():void {
    // this.userClone = JSON.parse(JSON.stringify(this.user))
  }

  public beforeDestroy():void {

  }

  deleteUser(){
    UserController.deleteUser(this.user);
  }

  save(){
    UserController.createUser(this.user);
  }

  get posts(){
    return this.user?.posts;
  }

  specific(){
    return this.user.hackedPosts;
  }

  duplicatePostHacked(mode:number, post:Post){
    this.user.hackedPosts = this.user.hackedPosts || {};
    this.user.hackedPosts[mode] = this.user.hackedPosts[mode] || [];
    this.user.hackedPosts[mode].push(post)
    this.save();
  }

  deletePostHacked(mode:number, postIndex:number){
    this.user.hackedPosts = this.user.hackedPosts || {};
    this.user.hackedPosts[mode] = this.user.hackedPosts[mode] || [];
    this.user.hackedPosts[mode] = this.user.hackedPosts[mode].filter((p,i)=>i!=postIndex)
    this.save();
  }


  duplicatePost(post:Post){
    this.user.posts.push(post);
    this.save();
  }

  deletePost(postIndex:number){
    this.user.posts = this.user.posts.filter((p,i)=>i!=postIndex)
    this.save();
  }

  addRandomPost(){
    this.user.posts.push(PostHelper.createRandomPost(this.user.posts.length+1))
  }
  addRandomPostHacked(mode:number){
    this.user.hackedPosts = this.user.hackedPosts || {};
    this.user.hackedPosts[mode] = this.user.hackedPosts[mode] || [];
    this.user.hackedPosts[mode].push(PostHelper.createRandomPost(_.random(1,100000000)))
  }
}
