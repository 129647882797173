


















import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import AuthController from "@/controllers/AuthController"

@Component({
  components:{}
})
export default class LoginComponent extends Vue {
  email = "";
  password = "";

  get user(){
    return AuthController.user;
  }

  get loginStatus(){
    return AuthController.status;
  }
  

  async signIn(){
    try{
      await AuthController.signIn(this.email, this.password)
      this.$router.push('/admin')
    }catch(x_x){

    }
    
  }

  private signOut(){
    AuthController.signOut();
  }

}
