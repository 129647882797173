

























import Post from '@/models/Post';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";

@Component({
  components:{}
})
export default class PostEdit extends Vue {
  @Prop() post!:Post;

  public mounted():void {
    
  }

  public beforeDestroy():void {
    
  }

}
