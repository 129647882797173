









import LoginComponent from '@/components/admin/LoginComponent.vue';
import UserList from '@/components/admin/UserList.vue';
import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import AuthController from "@/controllers/AuthController"

@Component({
  components:{
    UserList,
  }
})
export default class Admin extends Vue {

  public get user(){
    return AuthController.user;
  }
  
  @Watch("user")
  public onUserChange(user:any){
    if(!user){
      this.$router.push('/auth')
    }
  }

  private signOut(){
    AuthController.signOut();
  }

  public mounted():void {
  }

  public beforeDestroy():void {
    
  }

}
