import { randomName } from '@/assets/data/Names';
import User from '@/models/User';
import _ from 'lodash'
import PostHelper from './PostHelper';

export default class UserHelper{

  
  public static createUser():User{
    return {
      uid:"test",
      username:randomName(),
      posts:[PostHelper.createRandomPost(1)],
      hackedPosts:{
        1:[],
        2:[],
        3:[],
      }
    }
  }
}