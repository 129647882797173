












import { Component, Inject, Model, Prop, Vue, Watch, Provide } from "vue-property-decorator";
import UserController from "@/controllers/UserController"
import UserHelper from "@/helpers/UserHelper"
import UserEdit from './UserEdit.vue';

@Component({
  components:{
    UserEdit
  }
})
export default class UserList extends Vue {
  get users(){
    return UserController.users
    
  }

  public mounted():void {
    
  }

  public beforeDestroy():void {
    
  }

  public create(){
    UserController.createUser(UserHelper.createUser())
  }

}
