export function randomName(){
  return NAMES[Math.floor(Math.random() * NAMES.length)];
}

export function name(index:number){
  return NAMES[index % NAMES.length];
}

const NAMES = [
  "Elina Vinson",
  "Dixie Hayden",
  "Amin Stein",
  "Earl Parra",
  "Sahara Ayala",
  "Aedan Fischer",
  "Nimrah Smyth",
  "Kaydee Thornton",
  "Margaret Harper",
  "Greg Haas",
  "Said O'Reilly",
  "Emmy Davison",
  "Julius Hawes",
  "Cecelia Mccartney",
  "Livia Paine",
  "Ellena Lynn",
  "Andreas Power",
  "Waleed Baxter",
  "Archibald Hamilton",
  "Ziva Franklin",
  "Tyrese Maxwell",
  "Jordanne Correa",
  "Jasmine Miller",
  "Aditya Hatfield",
  "Toni Gale",
  "Samara Benson",
  "Alexa Burgess",
  "Eamonn Merrill",
  "Brendon Kendall",
  "Ephraim O'Doherty",
  "Manav Calderon",
  "Kyra Fountain",
  "Taya Mcclure",
  "Kajol Hopkins",
  "Cali Sullivan",
  "Mehmet Hanna",
  "Antonina Carey",
  "Catrina Mcclain",
  "Sania Hubbard",
  "Dannielle Schroeder",
  "Charlie Stevens",
  "Rosalie Carroll",
  "Denzel Barron",
  "Macaulay Sanderson",
  "Zoya Mejia",
  "Fleur John",
  "Chanelle Alexander",
  "Sia Mcconnell",
  "Jaylen Bass",
  "Jayson Mccullough",
  "Abdurrahman Clifford",
  "Matilda Hughes",
  "Suranne Acosta",
  "Caelan Rodgers",
  "Eleasha Curtis",
  "Natalya Singh",
  "Reegan Stone",
  "Lance Plummer",
  "Honey Callaghan",
  "Yusra Greene",
  "Sonia Zuniga",
  "Melvin Cook",
  "Mandy Herman",
  "Heath Sheppard",
  "Pauline Bautista",
  "Erica Field",
  "Blanche Hull",
  "Nellie Love",
  "Rhiann Casey",
  "Huma Chase",
  "Donte Duarte",
  "Huxley Fox",
  "Karan Henson",
  "Igor Watkins",
  "Hadassah Brown",
  "Tracy Davis",
  "Shayna Wade",
  "Leia Khan",
  "Shanice Kidd",
  "Hana Hussain",
  "Luci Pate",
  "Shona Pruitt",
  "Kareena Silva",
  "Maggie Sargent",
  "Manahil Villegas",
  "Nigel Moore",
  "Ethan Legge",
  "Colm Pratt",
  "Eira Mooney",
  "Isobelle Olson",
  "Aislinn Zavala",
  "Gino Delarosa",
  "Zavier Jefferson",
  "Tahlia Gates",
  "Beulah Costa",
  "Waqar Norris",
  "Zeynep Mustafa",
  "Tasha Boyer",
  "Beauden Valenzuela",
  "Verity Mair",
]